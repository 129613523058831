const ServicesList = [
    {
        id:1,
        Service:"Graphic Design ",
        path:"/service/design"
    },
    {
        id:2,
        Service:"Digital Marketing",
        path:"/service/digital-marketing"
    },
    {
        id:3,
        Service:"Website Development",
        path:"/service/web-development"
    },
    {
        id:4,
        Service:"Software Development",
        path:"/service/software-development"
    },
    {
        id:5,
        Service:"Business Consultancy ",
        path:"/service/business-consultation"
    }
]

export default  ServicesList;