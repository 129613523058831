import main1 from "../../img/team/Vector 24.png";
// import main2 from '../../img/team/team-2.png';
// import main3 from '../../img/team/team-3.png';
// import main4 from '../../img/team/team-4.png';
// import main5 from '../../img/team/team-5.png';
// import main6 from '../../img/team/team-6.png';
// import main1 from '../../img/team/Vector 24.png';
import main2 from "../../img/team/Vector 83.png";
import main3 from "../../img/team/vishal.png";
import main4 from "../../img/team/Huzaifa.png";
import main5 from "../../img/team/swapnil.png";
import prashant from "../../img/team/Prasant.png";
import ashish from "../../img/team/Ashish.png";
import vipul from "../../img/team/Vector 84.png";

const TeamData = [
  {
    id: 1,
    img: main1,
    name: "Diksha Kuriyal",
    title: "Founder, CEO",
    about:
      "Our CEO, Diksha likes everything organized. Her room, her work, and her about section. Two years ago, she founded this creative agency to provide small businesses and corporations with delightful marketing services. Now? She helps businesses grow their audience and brand recognition through online modes. Along with prioritizing personal development she also focuses on developing others that makes her an effective leader. When she's not working, It's either caffeine or another set at the gym.",
  },
  {
    id: 2,
    img: main2,
    name: "Nancy Herald",
    title: "Business Development Executive",
    about:
      "I started my career as a professional nurse, working in the ICU, emergency, and many other wards. It was rewarding, but I eventually wondered if it was truly where I wanted to be. So, I took a leap and switched to the corporate world, starting in customer care and then working as an admission counselor with UpGrad.One day, a close friend told me about TechQilla, and I decided to give it a shot. I cleared the interview and now work as a Brand Strategist. I love my job because I get to meet new people every day and learn from their unique experiences. Whether it's seasoned business owners or fresh startups, there's always something new to discover. I’m excited to keep growing and helping businesses thrive in this role.",
  },
  {
    id: 3,
    img: main3,
    name: "Vishal Kuriyal",
    title: "Creative Head at Designing",
    about:
      "It’s tough to write about our creative head, more so because for him everything is about the visuals. Vishal has been working for 3 years and does graphic designing, illustration, UI design, and video editing.  You could describe Vishal as a gym freak, a font aficionado, and an eternal seeker of inspiration for his designs. Vishal believes in collaboration while not forgetting about his own journey.",
  },
  {
    id: 4,
    img: main4,
    name: "Huzaifa Ahmed",
    title: "Vice President of Sales",
    about:
      "I'm Huzaifa, whose life resembles a journey on a moving train, navigating diverse places and experiences. From schooling in Jeddah, KSA, and Chennai to a bachelor's and master's degree in UP and Gurgaon respectively, my education reflects my travels. Initially drawn to science, particularly physics, I later chose commerce to explore new horizons and opportunities.Today, I find myself at TechQilla, where I continue to explore the world, leveraging every opportunity to enhance my skills and knowledge. As a brand strategist at TechQilla, I am working hard to achieve targets and take the company to new heights with my sales and marketing skills. My background in commerce allows me to bring a unique perspective to our brand strategies, integrating technical insights with market dynamics to drive success.",
  },
  {
    id: 5,
    img: main5,
    name: "Swapnil Tripathi",
    title: "Business Development Executive",
    about:
      "My journey began with a strong science background during my school years, paired with a deep passion for cricket. After leaving the cricket field, I pursued a degree in Mechanical Engineering. Throughout my studies, I realized my true calling was in engaging with people, thanks to my extroverted nature. This led me to a career in sales and marketing, where I could combine my technical expertise with my love for interpersonal interaction. Now, as a Brand Strategist at TechQilla, I am excited to be doing exactly what I love. Here, I leverage my diverse experiences to help brands grow and succeed, bringing my career aspirations to life.",
  },
  {
    id: 6,
    img: prashant,
    name: "Prashant Semwal",
    title: "Digital Marketing Executive",
    about:
      "From an early age, I was intrigued by virtual gaming and technology. This passion naturally progressed into a deep interest in the digital world, ultimately leading me toward specializing in digital marketing. Over the past two years, I've been focused on enhancing online visibility for businesses through SEO and PPC. As a Brand Strategist, I leverage data-driven insights to develop effective digital marketing campaigns. I enjoy combining creativity with strategic thinking to drive business growth. And yes, One Piece is real.",
  },
  {
    id: 7,
    img: vipul,
    name: "Vipul Pandey",
    title: "Head of project management associate",
    about:
      "I began my career as a Business Development Associate at Byju's, where I honed my skills in strategic growth and client engagement. Recognizing an opportunity for advancement, I successfully navigated the interview process at Techqilla and transitioned into the role of Head of Project Management Associate. In this role, I thrive in a dynamic environment that fosters creativity and innovation, which fuels my passion for project management.",
  },
  {
    id: 8,
    img: ashish,
    name: "Ashish Verma",
    title: "Legal Advisor",
    about:
      "Advocate Ashish Verma, a graduate of Campus Law Centre, Faculty of Law, University of Delhi (2023), is a dynamic legal professional practising in Delhi's District Courts and High Court. Ashish's legal journey is driven by a desire to make a difference. He approaches each case with an open mind and a commitment to continuous learning. His practice is characterised by a blend of intellectual curiosity, empathy, and dedication to justice. Ashish Verma's approach to law is characterized by a blend of intellectual curiosity, empathy, and a steadfast commitment to justice. Whether dealing with high-stakes litigation, offering counsel on sensitive matters, or contributing to innovative startups, he brings the same level of dedication and insight to every endeavour. As he continues to build his multifaceted career, Ashish remains focused on leveraging his skills and knowledge to navigate the complexities of the legal system while making a positive impact on society and the business world.",
  },
];

export default TeamData;
